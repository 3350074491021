<template>
  <div class="Files">
    <function-bar></function-bar>
    <icon-type-list></icon-type-list>
    <!-- <div style="width: 100%" class="IconTypeList">
      <file-menu></file-menu>
      <file-menu></file-menu>
      <file-menu></file-menu>
    </div> -->
    <!-- <div style="width: 100%">
      <ul style="width: 100%;height: 500px;blue;margin: 0 auto;">
        <li style="list-style: none;width:200px;height: 200px;yellow;float: left;margin-left: 10px;margin-right: 10px;margin-top:5px;">1</li>
        <li style="list-style: none;width:200px;height: 200px;yellow;float: left;margin-left: 10px;margin-right: 10px;margin-top:5px;">1</li>
        <li style="list-style: none;width:200px;height: 200px;yellow;float: left;margin-left: 10px;margin-right: 10px;margin-top:5px;">1</li>
        <li style="list-style: none;width:200px;height: 200px;yellow;float: left;margin-left: 10px;margin-right: 10px;margin-top:5px;">1</li>
        <li style="list-style: none;width:200px;height: 200px;yellow;float: left;margin-left: 10px;margin-right: 10px;margin-top:5px;">1</li>
        <li style="list-style: none;width:200px;height: 200px;yellow;float: left;margin-left: 10px;margin-right: 10px;margin-top:5px;">1</li>
        <li style="list-style: none;width:200px;height: 200px;yellow;float: left;margin-left: 10px;margin-right: 10px;margin-top:5px;">1</li>
        <li style="list-style: none;width:200px;height: 200px;yellow;float: left;margin-left: 10px;margin-right: 10px;margin-top:5px;">1</li>
        <li style="list-style: none;width:200px;height: 200px;yellow;float: left;margin-left: 10px;margin-right: 10px;margin-top:5px;">1</li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import FunctionBar from "./FunctionBar.vue";
import IconTypeList from "./IconTypeList.vue";
// import FileMenu from "../module/FileMenu.vue";
export default {
  name: "Files",
  data() {
    return {
      // 排序方式
      sortType: "time",
      // 展示方式 icon table
      showType: "icon",
      // 搜索的文件夹
      searchFolder: [],
    };
  },
  components: {
    FunctionBar,
    IconTypeList,
    // FileMenu,
  },
  created() {},
  mounted() {},
  watch: {
    "$store.state.parentFolder"(current) {
      current;
      this.getData();
    },
  },
  methods: {
    // 请求文件列表
    async getData() {
      window.console.log(this.$store.state.parentFolder);
      let parentFolder = this.$store.state.parentFolder;
      let fileNumber = 0;
      if (parentFolder.length == 0) {
        let res = await this.$http.getFileByParent(fileNumber);
        if (res !== undefined && res.status == 200) {
          this.$store.commit("updateDataList", res.data);
        }
      } else {
        let res = await this.$http.getFileByParent(
          parentFolder[parentFolder.length - 1].fileNumber
        );
        if (res !== undefined && res.status == 200) {
          this.$store.commit("updateDataList", res.data);
        }
      }
    },
  },
};
</script>

<style scoped>
.Files {
  width: 100%;
}
.IconTypeList {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 10px 20px 0;
}
</style>

<template>
  <!-- -->
  <div class="Attribute" v-show="status" @mousedown="moveDialog" :style="{
    top: y + 'px',
    left: x + 'px',
  }">
    <div class="header">
      {{
      (file.fileName) | handleLongString(10, 15)
      }}
      <i class="iconfont icon-close" @click="close()"></i>
    </div>
    <div class="group">
      <div class="groupItem">
        <span class="left">文件类型:</span>
        <span class="right">
          {{ file.fileType }}
        </span>
      </div>
    </div>
    <div class="group">
      <div class="groupItem">
        <span class="left">逻辑路径:</span>
        <span class="right">{{ logicPath }}</span>
      </div>
      <div class="groupItem">
        <span class="left">大小:</span>
        <span class="right">
          {{
          (file.fileSize) | fileSizeString(file.fileSize)
          }}
        </span>
      </div>
      <div class="groupItem">
        <span class="left">创建时间:</span>
        <span class="right">{{ file.creationTime }}</span>
      </div>
    </div>
    <div class="group">
      <div class="groupItem">
        <span class="left">文件编号:</span>
        <span class="right">{{ file.fileNumber }}</span>
      </div>
      <div class="groupItem">
        <span class="left">存储路径:</span>
        <span class="right">{{ file.filePath }}</span>
      </div>
      <div class="groupItem">
        <span class="left">MD5:</span>
        <span class="right">{{ file.md5 }}</span>
      </div>
    </div>
    <div class="group" v-for="item in banks" :key="item.diskId">
      <div class="groupItem">
        <span class="left">备份磁盘Id:</span>
        <span class="right">{{ item.diskId }}</span>
      </div>
      <div class="groupItem">
        <span class="left">创建时间:</span>
        <span class="right">{{ item.creationTime }}</span>
      </div>
      <div class="groupItem">
        <span class="left">检查时间:</span>
        <span class="right">{{ item.updateTime }}</span>
      </div>
      <div class="groupItem">
        <div @click="checkFile(item)">{{ item.name }}</div>
        <div @click="downloadFile(item)">下载备份</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { handleLongString, fileSizeString } from "../plugins/utils.js";

export default {
  name: "Attribute",
  props: ['value'],
  data() {
    return {
      status: false,
      x: 0,
      y: 0,
      file: {
        fileName: '',
        fileType: '',
        fileSize: 0,
        creationTime: ''
      },
      logicPath: '',
      banks: [],
    };
  },
  watch: {
    value: {
      handler(new_value, old_value) {
        old_value
        if (new_value.status) {
          this.calculatePosition();
          this.getData();
        } else {
          //TODO
          this.close();
        }
      },
      deep: true,
    },
  },
  computed: {
  },
  methods: {
    async getData() {
      let res = await this.$http.getFileSenior(this.value.fileNumber);
      if (res !== undefined && res.status == 200) {
        this.file = res.data.file
        this.logicPath = res.data.logicPath

        let data = []
        for (let i = 0; i < res.data.banks.length; i++) {
          res.data.banks[i].name = "检查备份文件"
          data.push(res.data.banks[i])
        }
        this.banks = data
      }
    },
    /**
 * 下载文件
 */
    async downloadFile(item) {
      let alink = document.createElement('a');
      alink.style.display = 'none';
      alink.href = axios.defaults.baseURL + "file/nodeDownload/" + item.diskId + "/" + item.fileNumber;
      document.body.appendChild(alink);
      alink.click();
      document.body.removeChild(alink); //下载完成移除元素
    },
    /**
   * 检查文件
   */
    async checkFile(item) {
      let res = await this.$http.checkFile(item.diskId + "/" + item.fileNumber);
      if (res !== undefined && res.status == 200) {
        //TODO
        item.name = res.data
      } else {
        item.name = res.msg
      }
    },


    // 拖动属性框的回调
    moveDialog(e) {
      let dialog;
      if (e.target.className != "Attribute") {
        dialog = e.target.parentNode;
      } else {
        return;
      }
      //算出鼠标相对元素的位置
      let disX = e.clientX - dialog.offsetLeft;
      let disY = e.clientY - dialog.offsetTop;
      let pageWidth = document.body.clientWidth;
      let pageHeight = document.body.clientHeight;
      const _this = this;
      // console.log(pageWidth, pageHeight);
      document.onmousemove = (e) => {
        // console.log(e);
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        top = top < 100 ? 100 : top;
        top = top > pageHeight - 225 ? pageHeight - 225 : top;
        left = left < 200 ? 200 : left;
        left = left > pageWidth - 325 ? pageWidth - 325 : left;
        // console.log(top, left);

        //移动当前元素
        dialog.style.left = left + "px";
        dialog.style.top = top + "px";

        _this.childInfo = {
          x: left,
          y: top,
          status: this.value.status,
        }
      };
      // 鼠标松开时清空事件
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
 * 计算菜单生成位置
 */
    calculatePosition() {
      let screen = this.getScreen();
      let x = 0;
      let y = 0;
      y = this.value.y + 100 > screen.height ? this.value.y - 100 : this.value.y;
      x = this.value.x + 245 > screen.width ? this.value.x - 245 : this.value.x;

      this.x = x
      this.y = y
      this.status = this.value.status
    },
    /**
     * 获取窗口大小
     */
    getScreen() {
      return {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      }
    },
    /**
     * 统一关闭菜单入口
     */
    close() {
      this.status = false
      this.x = 0
      this.y = 0
    },
  },
  created() { },
  filters: {
    handleLongString, fileSizeString
  },
};
</script>

<style scoped>
.Attribute {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
  padding-bottom: 10px;
  border-radius: 10px;
  width: 300px;
  font-size: 14px;
  position: fixed;
  background-color: #fff;
  z-index: 100;
}

.header {
  padding: 15px;
  /* position: relative; */
  cursor: move;
  user-select: none;
}

.header i {
  position: absolute;
  right: 10px;
  color: rgb(124, 124, 124);
  cursor: pointer;
}

.group {
  padding: 5px 0;
  margin: 0 15px;
  border-top: 1px solid #ddd;
}

.groupItem {
  padding: 5px 0;
  display: flex;
}

.groupItem .left {
  width: 80px;
}

.groupItem>div {
  padding: 4px 20px;
  font-size: 14px;
  color: rgb(70, 70, 70);
  user-select: none;
}

.groupItem>div:hover {
  background-color: #696bcc;
  color: white;
}
</style>

<template>
  <div class="AsideBar">
    <div v-for="item in asideBarData" :key="item.path" class="barItem" :class="$route.path == item.path ? 'select' : ''"
      @click="go(item.path)">
      <i :style="{ color: $route.path == item.path ? '#595bb3' : '' }" :class="'iconfont icon-' + item.icon"></i>
      <span :style="{ color: $route.path == item.path ? '#595bb3' : '' }">{{ item.name }}</span>
    </div>
    <!-- <div class="barItem" :class="selectIndex == 2 ? 'select' : ''" @click="go(2)">
      <i :style="{ color: selectIndex == 2 ? '#595bb3' : '' }" class="iconfont icon-favorite"></i>
      <span :style="{ color: selectIndex == 2 ? '#595bb3' : '' }">收藏夹</span>
    </div>
    <div class="barItem" :class="selectIndex == 2 ? 'select' : ''" @click="go(3)">
      <i :style="{ color: selectIndex == 2 ? '#595bb3' : '' }" class="iconfont icon-favorite"></i>
      <span :style="{ color: selectIndex == 2 ? '#595bb3' : '' }">用户管理</span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "AsideBar",
  data() {
    return {
      asideBarData: [
        { path: "/files", name: "文件", icon: "wenjian" },
        { path: "/collectes", name: "收藏夹", icon: "favorite" }
      ],
    };
  },
  watch: {
    "$store.state.userId"(current) {
      if (current == 1)
        this.asideBarData = [
          { path: "/files", name: "文件", icon: "wenjian" },
          { path: "/collectes", name: "收藏夹", icon: "favorite" },
          { path: "/users", name: "管理", icon: "favorite" },
        ]
    },
  },
  methods: {
    go(path) {
      if (this.$route.path != path)
        this.$router.push({
          path: this.redirect || path,
        });
    },
  },
  created() {
  },
};
</script>

<style scoped>
i {
  font-size: 24px;
  margin-right: 10px;
}

.barItem {
  display: flex;
  align-items: center;
  margin: 0 10px;
  padding: 10px 15px;
  border-radius: 13px;
  cursor: pointer;
}

.barItem:hover {
  background-color: #ececee;
}

.select {
  background-color: rgb(231, 231, 231) !important;
}
</style>

<template>
  <div class="iconTypeListContainer" :class="[
    banScroll ? 'banScroll' : '',
  ]" @contextmenu.prevent.stop="showRightMenu($event, null, 'blank')">
    <!-- 路径条 -->
    <el-breadcrumb separator="/" class="goLastFolder" v-show="parentFolder.length > 1">
      <el-breadcrumb-item v-for="item in parentFolder" :key="item.fileNumber"><a @click="openItem(item)" href="#" @drop="moveItem(item)" @dragover="e => e.preventDefault()" :draggable="false">{{
            item.fileName
          }}</a></el-breadcrumb-item>
    </el-breadcrumb>
    <div class="IconTypeList">
      <!-- 文件夹 -->
      <div class="listItem" :class="selectFiles.find((id) => id == item.fileNumber) ? 'selectFile' : ''" v-for="item in currentChildrenFolder" :key="item.fileNumber" @dblclick="openItem(item)" @contextmenu.prevent.stop="showRightMenu($event, item, 'folder')" @click="selectList($event, item)" @drop="moveItem(item)" @dragover="e => e.preventDefault()" @dragstart="onDragItemStart($event, item)" @dragend="onDragEndItem" :draggable="isDraggable">
        <div class="imgContainer">
          <img src="../assets/img/folder.png" alt="" :draggable="false" />
        </div>
        <textarea type="text" class="renameTextarea" :id="'input' + item.fileNumber" :disabled="!selectFiles.find((id) => id == item.fileNumber)" v-model="item.fileName" @blur="renameDone(item)" @keyup.enter="renameDone2(item.fileNumber)" @click.stop="renameFolder(item, true)" @dblclick.stop="" />

        <!-- <textarea class="renameInput"  style="height:300px;width:100px;" name="" id="" cols="300" rows="100"  v-model="item.fileName"></textarea> -->
      </div>
      <!-- 新建文件夹 -->
      <div class="listItem selectFile" v-if="$store.state.createFolder">
        <img src="../assets/img/folder.png" alt="" :draggable="false" />
        <input type="text" id='input0' ref="createName" v-model="createName" class="renameInput" @blur="renameDone()" @keyup.enter="renameDone2(0)" />
      </div>

      <!-- <file-menu></file-menu> -->
      <!-- 文件列表 -->
      <!-- :draggable="false" -->
      <div class="listItem" :class="selectFiles.find((id) => id == item.fileNumber) ? 'selectFile' : ''" v-for="item in listData" :key="item.fileNumber" @click="selectList($event, item)" @dblclick="openItem(item)" @contextmenu.prevent.stop="showRightMenu($event, item, 'file')" slot="reference" @dragstart="onDragItemStart($event, item)" @dragend="onDragEndItem" :draggable="isDraggable">
        <div class="imgContainer">
          <!-- <img src="../assets/img/music.png" alt="" v-if="item.fileType == 'audio'" :draggable="false" /> -->
          <img :src="item.zoomUrl" alt="" v-if="item.fileType == 'image'" :draggable="false" />
          <!-- 视频文件 -->
          <div class="videoContainer" v-else-if="item.fileType == 'video'">
            <video :src="item.zoomUrl" class="videoItem" preload="meta"></video>
            <div class="iconContainer">
              <i class="iconfont icon-play_nor"></i>
            </div>
          </div>
          <img :src="require('../assets/img/' + item.fileType + '.png')" alt="" v-else :draggable="false" />
          <!-- 用于无预览模式 -->
          <!-- <img :src="require('../assets/img/' + item.fileType + '.png')" alt="" :draggable="false" /> -->
          <!-- 是否收藏 -->
          <img src="../assets/img/collect.png" alt="" class="collectIcon" v-if="item.collection" :draggable="false" />
        </div>
        <textarea type="text" class="renameTextarea" :id="'input' + item.fileNumber" :disabled="!selectFiles.find((id) => id == item.fileNumber)" v-model="item.fileName" @blur="renameDone(item)" @keyup.enter="renameDone2(item.fileNumber)" @click.stop="renameFolder(item, true)" @dblclick.stop="renameSelect(item.fileNumber)" />
        <!-- <input type="text" class="renameInput" :id="'input' + item.fileNumber" :disabled="!selectFiles.find((id) => id == item.fileNumber)" v-model="item.fileName" @blur="renameDone(item)" @keyup.enter="renameDone2(item.fileNumber)" @click.stop="renameFolder(item, true)" @dblclick.stop="renameSelect(item.fileNumber)" /> -->
      </div>
    </div>
    <!-- 刷新遮罩层 -->
   <div  v-show="maskIs" class="popContainer">
      数据加载中...
   </div>
    <!-- 文件右击菜单框组件 -->
    <RightMenu v-model="menuSetting" :item="selectItem"></RightMenu>
    <!-- 图片预览组件 -->
    <image-player v-model="isImgDialogShow"></image-player>
    <!-- 属性框组件 -->
    <attribute v-model="attributes"></attribute>
    <!-- 删除框组件 -->
    <delete-file v-model="deleteFile"></delete-file>

    <!-- 移动到中选择文件夹的dialog -->

    <!-- 搜索为空的提醒 -->

    <!-- 返回顶部按钮 -->
    <go-top scrollObj=".iconTypeListContainer"></go-top>
    <!-- 拖动时的预览容器 -->

    <!-- 拖动时的预览容器 -->
    <div class="dragImgContainer" :class="showDragImgContainer ? 'showDragImgContainer' : ''" :style="[
      { top: dragImgContainerPosition.y + 'px' },
      { left: dragImgContainerPosition.x + 'px' },
    ]"></div>
  </div>
</template>

<script>
import axios from "axios";
import RightMenu from "../module/RightMenu.vue";
import Attribute from "../module/Attribute.vue";
import DeleteFile from "../module/DeleteFile.vue";
import ImagePlayer from "../module/ImagePlayer.vue";
import GoTop from "../module/GoTop.vue";
// import FileMenu from "../module/FileMenu.vue";
export default {
  components: {
    Attribute,
    DeleteFile,
    ImagePlayer,
    RightMenu,
    GoTop,
    // FileMenu,
  },
  name: "IconTypeList",
  data() {
    return {
      //用于显示遮罩层
      maskIs:false,
      //用于修复拖拽生效后输入框不正常工作的问题
      isDraggable: true,
      //用于重命名为空的回写
      rename: "",
      writeItem: "",
      //选择的文件列表
      selectFiles: [],
      // 右击的item
      rightClickItem: {},
      // 新建文件夹名称
      createName: "新建文件夹",
      // 是否显示图片diaload
      isImgDialogShow: { status: false, url: "", name: "" },
      // 当前目录的子目录
      currentChildrenFolder: [],
      //文件列表
      listData: [],
      // 是否禁止页面滚动
      banScroll: false,
      //右击菜单
      menuSetting: { status: false, x: 0, y: 0, menuType: "file" },
      //属性框
      attributes: { status: false, x: 0, y: 0, fileNumber: 0 },
      //删除框
      deleteFile: { status: false, x: 0, y: 0, fileNumber: 0, fileName: "" },
      //选择的数据
      selectItem: {},
      //拖动框
      showDragImgContainer: false,
      //拖动位置
      dragImgContainerPosition: { x: 0, y: 0 },
    };
  },
  props: {},

  mounted() {
    // console.log(Card);
    // this.keyDown();
    // this.getData()
  },
  computed: {
    allData() {
      return this.$store.state.dataList;
    },
    parentFolder() {
      return this.$store.state.parentFolder;
    },
  },
  watch: {
    "$store.state.dataList"(current) {
      current;
      this.getData();
    },
    "$store.state.createFolder"(current) {
      if (current) {
        this.$nextTick(() => {
          this.$refs.createName.focus();
          this.$refs.createName.select();
        });
      }
    },
    selectItem: {
      handler(new_value, old_value) {
        new_value;
        old_value;
        if (new_value && new_value.fileNumber) {
          this.changeTextareaMaxHeight(new_value.fileNumber);
        }

        // if (new_value.status) {
        //   this.calculatePosition();
        //   this.getData();
        // } else {
        //   //TODO
        //   this.close();
        // }
      },
      deep: true,
    },
    $route(to, from) {
      // 对路由变化作出响应...
      to;
      from;
      this.getData();
    },
  },
  methods: {
    getData() {
      //显示遮罩层
      this.maskIs = true

      let current = this.$store.state.dataList;
      let files = [];
      let folders = [];
      for (let i = 0; i < current.length; i++) {
        if (current[i].fileType == "folder") {
          folders.push(current[i]);
        } else {
          current[i].url =
            axios.defaults.baseURL + "file/download/" + current[i].fileNumber;
          current[i].zoomUrl =
            axios.defaults.baseURL + "file/zoom/" + current[i].fileNumber;
          files.push(current[i]);
        }
      }
      this.currentChildrenFolder = folders;
      this.listData = files;
      for (let i = 0; i < current.length; i++) {
        this.changeTextareaHeight(current[i].fileNumber);
      }

 
      setTimeout(this.maskShow, 350);
    },
    maskShow() {
    this.maskIs = false
    },

    changeTextareaHeight(id) {
      if (!id) {
        return;
      }
      this.$nextTick(() => {
        var textarea = document.getElementById("input" + id);
        var scrollHeight = textarea.scrollHeight; // 控件所有的高度，包含滚动的那部分(不可见也会有高度)
        var height = textarea.offsetHeight; // 屏幕上显示的高度
        if (height <= scrollHeight) {
          if (scrollHeight > 48) {
            textarea.style.height = "auto"; // 恢复默认值，这个作用就是根据内容自适应textarea高度
            textarea.style.height = "48px"; // 拿到最新的高度改变textarea的高度
          } else {
            textarea.style.height = "auto"; // 恢复默认值，这个作用就是根据内容自适应textarea高度
            textarea.style.height = textarea.scrollHeight + "px"; // 拿到最新的高度改变textarea的高度
          }
        }
      });
    },
    changeTextareaMaxHeight(id) {
      if (!id) {
        return;
      }
      this.$nextTick(() => {
        var textarea = document.getElementById("input" + id);

        // window.console.log(  textarea.getRow())
        var scrollHeight = textarea.scrollHeight; // 控件所有的高度，包含滚动的那部分(不可见也会有高度)
        // window.console.log(scrollHeight + "高度");
        var height = textarea.offsetHeight; // 屏幕上显示的高度
        if (height <= scrollHeight) {
          textarea.style.height = "auto"; // 恢复默认值，这个作用就是根据内容自适应textarea高度
          textarea.style.height = textarea.scrollHeight + "px"; // 拿到最新的高度改变textarea的高度
        }
      });
    },
    //通过isCtrl, 可通过ctrl进行多选
    //TODO 没有 isShift
    selectList(e, item) {
      //点击了空白
      for (let i = 0; i < this.selectFiles.length; i++) {
        this.changeTextareaHeight(this.selectFiles[i]);
      }
      if (item == null && !e.ctrlKey) {
        this.selectFiles = [];
        return;
      }
      if (e.ctrlKey) {
        let i = this.selectFiles.findIndex((id) => id == item.fileNumber);
        if (i == -1) {
          this.selectFiles.push(item.fileNumber);
        } else {
          this.selectFiles.splice(i, 1);
        }
      } else {
        this.selectFiles = [];
        this.selectFiles.push(item.fileNumber);
        //点击后直接最大化文本框
        this.changeTextareaMaxHeight(item.fileNumber);
      }
    },

    //移动文件到文件夹
    async moveItem(item) {
      //因为历史遗留原因,需要对ids进行额外处理以符合后端API接口规范
      //对axios Http方法进行额外的处理,让put post 也能在url上带自定义参数
      // 2023.1.30 by @bjtang
      if (this.selectFiles.length == 0) return;
      let idsDto = { idsDto: [] };
      for (let i in this.selectFiles)
        idsDto.idsDto.push({ id: this.selectFiles[i] });
      let res = await this.$http.moveFile(idsDto, false, {
        data: item.fileNumber,
      });
      if (res !== undefined && res.status == 200) {
        //TODO
        this.refresh();
      } else {
        //TODO
      }
    },
    onDragItemStart(e, item) {
      // 判断item是否被选中 选中则拖动所有选中的选项，否则只拖动当前item
      if (this.selectFiles.find((id) => id == item.fileNumber)) {
        // 选中
      } else {
        //没有选中
        this.selectList({ ctrlKey: false }, item);
      }
      // console.log(e.path[0]);
      // 确认自定义拖动框的位置
      this.dragImgContainerPosition.x = e.clientX + 5;
      this.dragImgContainerPosition.y = e.clientY + 5;
      // 向拖动款中插入img
      let imgContainer = document.querySelector(".dragImgContainer");

      // 将原来的幽灵效果换成透明图
      let img = document.createElement("img");
      e.dataTransfer.setDragImage(img, 0, 0);

      // window.console.log(img1)
      // imgContainer.appendChild(img1);
      // 判断item是否被选中
      this.selectFiles.some((id) => {
        if (imgContainer.children.length == 5) {
          return true;
        }
        let i = this.allData.find((item) => item.fileNumber == id);

        if (i.fileType != "video") {
          let img = document.createElement("img");
          // 图片
          if (i.fileType == "image") {
            img.setAttribute("src", i.zoomUrl);
          } else {
            img.setAttribute("src", require(`../assets/img/${i.fileType}.png`));
          }
          imgContainer.appendChild(img);
        } else if (i.fileType == "video") {
          let video = document.createElement("video");
          video.setAttribute("src", i.zoomUrl);
          video.setAttribute("preload", "meta");
          imgContainer.appendChild(video);
        }
      });
      this.$nextTick(() => {
        this.showDragImgContainer = true;
      });

      // if (this.myBrowser !== "FF") return;
      // 火狐浏览器存在bug  在drag事件中的 e不能获取到client值，所以用document作为目的地，通过dragover获得在document中的坐标值
      document.ondragover = (event) => {
        this.dragImgContainerPosition.x = event.clientX + 5;
        this.dragImgContainerPosition.y = event.clientY + 5;
      };
    },
    //拖动结束
    onDragEndItem() {
      document.ondragover = null;
      this.showDragImgContainer = false;
      let imgContainer = document.querySelector(".dragImgContainer");
      imgContainer.innerHTML = "";
    },
    //让input框生效/获得焦点/全选文字
    renameFolder(item, isInput = false) {
      let id = item.fileNumber;

      let i = this.selectFiles.findIndex((id) => id == id);
      if (i != -1 && isInput) {
        this.$nextTick(() => {
          //不要全选文字
          // document.querySelector('#input' + id).select()
          document.querySelector("#input" + id).focus();
          this.isDraggable = false;
        });
      }
      this.rename = item.fileName;
      this.writeName = item.fileName;
      this.selectItem = item;
    },
    //全选文字
    renameSelect(id) {
      this.$nextTick(() => {
        document.querySelector("#input" + id).select();
      });
    },

    showRightMenu(e, item, type) {
      //右击也要选中
      if (item != null) this.selectList({ ctrlKey: false }, item);

      // 如果属性框打开，则先关闭属性框
      this.attributes = {
        x: 0,
        y: 0,
        status: false,
      };

      // 如果删除框打开，则先关闭删除框
      this.deleteFile = {
        x: 0,
        y: 0,
        status: false,
      };
      //TODO
      //禁止页面滚动
      // this.banScroll = true;
      this.menuSetting = {
        status: true,
        x: e.clientX,
        y: e.clientY,
        menuType: type,
      };
      this.selectItem = item;
    },

    showAttribute(item, childInfo) {
      //打开属性框
      this.attributes = {
        x: childInfo.x,
        y: childInfo.y,
        status: true,
        fileNumber: item.fileNumber,
      };
    },
    showDeleteFile(item, childInfo) {
      //打开删除框
      this.deleteFile = {
        x: childInfo.x,
        y: childInfo.y,
        status: true,
        fileNumber: item.fileNumber,
        fileName: item.fileName,
        fileType:item.fileType
      };
    },

    // 重命名文件夹命名完成的回调
    async renameDone(data = null) {
      if (data == null) {
        this.createFolder();
        return;
      }

      //重命名
      if (data.fileName == "") {
        this.$message.warning("文件夹名称不能为空哦!");
        data.fileName = this.rename;
      } else {
        let fileData = data;
        fileData.creationTime = null;
        fileData.filePath = null;
        let res = await this.$http.editFile(fileData);
        if (res !== undefined && res.status == 200) {
          //TODO
        } else {
          //TODO
        }
      }

      this.isDraggable = true;
      this.rename = "";
    },

    //为了避免回车和失去焦点同时触发
    renameDone2(id) {
      //按下回车失去焦点
      document.querySelector("#input" + id).blur();
    },

    async createFolder() {
      //新建
      if (this.createName == "") {
        this.$message.warning("文件夹名称不能为空哦!");
        this.$store.commit("updateCreateFolder", false);
        this.createName = "新建文件夹";
      } else {
        // 发送请求给服务器
        let parentFolder = this.$store.state.parentFolder;
        let res = await this.$http.addFolder({
          fileName: this.createName,
          parentFolder: parentFolder[parentFolder.length - 1].fileNumber,
        });
        if (res !== undefined && res.status == 200) {
          this.$store.commit("updateCreateFolder", false);
          this.createName = "新建文件夹";
          this.refresh();
          this.selectList(res.data);
        } else {
          this.createName = "新建文件夹";
        }
      }
    },
    // 双击打开文件
    async openItem(item) {
      // 先判断打开的文件类型
      let fileType = item.fileType;
      //  打开的是文件夹
      //  路径条没有fileType属性
      if (fileType == null || fileType == "folder") {
        this.$store.commit("updateParentFolder", {
          fileNumber: item.fileNumber,
          fileName: item.fileName,
        });
        this.selectItem = {};
        this.selectFiles = [];
        //打开文件夹要清空选中状态，否则会出现找不到指定的input对象  2023.8.2 @bjtang
        return;
      }
      // 打开的是video文件
      //TODO
      // if (fileType == "video") {
      //   this.$store.commit("updateIsVideoPlayerShow", true);
      //   this.$store.commit("updateCurrentVideoInfo", item);
      // }
      // 打开的是音频文件
      //TODO
      // else if (fileType == "audio") {
      //   this.$store.commit("updateIsMusicPlayerShow", true);
      //   this.$store.commit("updateCurrentMusicInfo", item);
      // }
      // 打开的是图片文件
      else if (fileType == "image") {
        this.isImgDialogShow = {
          status: true,
          url: item.url,
          name: item.fileName,
        };
      }
      // 其它文件暂时无法直接打开 提醒暂时无法直接打开，可以下载后打开
      else {
        let res = await this.$http.getFilePreview(item.fileNumber);
        if (res !== undefined && res.status == 200) {
          var previewUrl =
            axios.defaults.baseURL +
            "preview/data/" +
            res.data +
            "&fullfilename=" +
            item.fileName;
          previewUrl = encodeURIComponent(previewUrl);
          previewUrl = unescape(previewUrl);
          previewUrl = window.btoa(previewUrl);

          window.open(
            "http://" +
              window.location.hostname +
              ":8012/onlinePreview?url=" +
              previewUrl
          );
        } else {
          this.$message.warning(
            "该文件暂时不能直接打开哦,可以下载后在本地打开!"
          );
        }
        //要预览文件的访问地址

        //   this.$message.warning("该文件暂时不能直接打开哦,可以下载后在本地打开!");
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.$store.commit("updateParentFolder", {
          fileNumber: -1,
          fileName: "",
        });
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.popContainer {
  height: calc(100vh - 100px);
  /* width: calc(100vw - 240px); */
  overflow-y: scroll;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align:center;   
  line-height:400px;
  background: rgba(255, 255, 255, 1);
}
.iconTypeListContainer {
  height: calc(100vh - 100px);

  /* width: calc(100vw - 240px); */
  overflow-y: scroll;
  position: relative;
}

.IconTypeList {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 10px 20px 0;
  /* height: 100%; */
}

.listItem {
  margin: 0 5px 10px;
  padding: 15px 5px 10px;
  text-align: center;
  width: 100px;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  /* height: 124px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* box-sizing: border-box; */
}

.collectIcon {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0px;
  right: 7px;
}

.imgContainer {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.listItem img {
  max-height: 90px;
  max-width: 90%;
  user-select: none;
}

.listItem:hover {
  background-color: rgba(105, 107, 204, 0.2);
}

.listItem:hover i {
  background-color: rgba(255, 255, 255, 0.5);
}

.selectFile {
  background-color: rgba(105, 107, 204, 0.4) !important;
}

.renameTextarea {
  width: 100px;
  text-align: center;
  border-style: none;

  text-overflow: ellipsis;
  background-color: rgba(255, 255, 255, 0);

  resize: none;
}
.renameInput {
  width: 100px;
  text-align: center;
  border-style: none;

  text-overflow: ellipsis;
  background-color: rgba(255, 255, 255, 0);
  /*   
  outline:medium;
  border:0; */
}

.renameInput:disabled {
  border-style: none;
  background-color: rgba(255, 255, 255, 0);
}

.videoContainer {
  height: 90px;
  width: 90px;
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.videoItem {
  height: 90px;
  width: 90px;
  border-radius: 5px;
  overflow: hidden;
}

.iconContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iconContainer i {
  color: #fcfcfc;
  font-size: 32px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s;
}

.goLastFolder {
  color: #595bb3;
  font-size: 14px;
  margin-left: 20px;
  left: 0;
}

.goLastFolder a {
  margin: 0 5px;
}


</style>

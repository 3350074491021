<template>
  <div class="Index">
    <el-container>
      <!-- 侧边栏 -->
      <el-aside width="178px">
        <div class="asideHeader">
          <div class="logo">
            <img src="../assets/imgs/logo.png" alt="" @dragstart.prevent />
          </div>
          <div class="name" style="color: #595bb3">网盘</div>
          <div class="name2" style="color: #595bb3">1.1.0</div>
        </div>
        <aside-bar></aside-bar>
        <user-info-card class="userInfoCard"></user-info-card>
      </el-aside>
      <el-main>
        <!-- <keep-alive>
        
        </keep-alive>   -->
        <router-view></router-view>
      </el-main>
    </el-container>
    <!-- 上传进度条提示框 -->
    <progress-dialog></progress-dialog>
  </div>
</template>

<script>
import AsideBar from "../components/AsideBar.vue";
import UserInfoCard from "../components/UserInfoCard.vue";
import ProgressDialog from "../module/ProgressDialog.vue";
export default {
  components: {
    AsideBar,
    UserInfoCard,
    ProgressDialog,
  },
  name: "Index",
  data() {
    return {};
  },
  methods: {
    // 请求
    // 上传至数据库的请求
    // 点击下载文件的回调
    // 放下拖拽文件的回调
  },
  watch: {},
};
</script>

<style scoped>
.el-aside {
  background-color: #f5f5f6;
  height: 100vh;
}

.el-main {
  width: calc(100vw - 240px);
}

.asideHeader {
  display: flex;
  align-items: center;
  margin: 20px;
  position: relative;
}

.asideHeader .logo {
  width: 50px;
}

.asideHeader .logo img {
  width: 100%;
}

.asideHeader .name {
  color: #25262b;
  font-size: 20px;
  letter-spacing: 4px;
  font-weight: bold;
  margin-left: 7px;
}


 .name2 {
  font-size: 10px;
}

.userInfoCard {
  position: absolute;
  bottom: 0;
}
</style>

<template>
  <div>
    <div class="FunctionBar">
      <div class="left">
        <el-upload multiple action="" :http-request="uploadFile" class="uploadButton" :show-file-list="false">
          <el-button type="primary" size="small" class="upload">
            <i class="iconfont icon-yunshangchuan"></i> 上传
          </el-button>
        </el-upload>
        <el-button size="small" class="create" @click="createFolder()">
          <i class="iconfont icon-add"></i> 新建
        </el-button>
        <!-- <el-button size="small" class="selectAll" :class="isSelectAll ? 'select' : ''" @click="selectAll">
          <i class="iconfont icon-complete"></i>
          全选
        </el-button> -->
      </div>
      <!-- 右边 -->
      <div class="right">
        <div class="search">
          <el-input placeholder="请输入内容" suffix-icon="el-icon-search" v-model="searchContent"
            @keyup.native.enter="fileSearch">
          </el-input>
        </div>
        <!-- <div class="sortType" v-if="false">
          <el-popover width="150" trigger="hover" :visible-arrow="false">
            <div class="sortTypeItem" @click="123">
              <i class="iconfont icon-select"></i>
              按修改时间排序
            </div>
            <div class="sortTypeItem" @click="123">
              <i class="iconfont icon-select"></i>
              按文件大小排序
            </div>
            <i slot="reference" class="iconfont icon-paixu"></i>
          </el-popover>
        </div> -->
        <!-- <div class="displayType" @click="changeShowType">
          <i class="iconfont icon-paixu1"></i>
        </div> -->
      </div>

    </div>
    <div class="tableHead" v-if="false">
      <div class="tableName tableHeadName">文件名</div>
      <div class="tableCollect">收藏</div>
      <div class="tableItemSize">大小</div>
      <div class="tableItemCreateTime">修改日期</div>
    </div>
  </div>
</template>

<script>

import { getType } from "../plugins/utils.js";
export default {
  name: "FunctionBar",
  components: {},
  data() {
    return {
      //   是否点击了全选按钮
      isSelectAll: false,
      // 上传按钮是否是加载状态
      isUploadBtnLoading: false,
      // 是否显示多选操作按钮
      isMultBtnsShow: false,
      // 搜索内容
      searchContent: "",
    };
  },
  watch: {

  },
  computed: {
    parentFolder() {
      return this.$store.state.parentFolder
    }
  },
  methods: {
    selectAll() {
      this.isSelectAll = !this.isSelectAll;
      //this.$store.commit("updateIsSelectAll", this.isSelectAll);
    },
    // 点击新建
    createFolder() {
      this.$store.commit("updateCreateFolder", true);
    },
    //文件上传
    async uploadFile(file) {
      const _this = this;
      file.name = file.file.name;
      //上传前
      let arr = this.$store.state.uploadProgressList;
      arr.push({ name: file.name, progress: 0 });
      this.$store.commit("updateUploadProgressList", arr);
      ///////

      let config = {
        timeout: 1000 * 60 * 60, //设置额外的超时时间
        onUploadProgress: function (progressEvent) {
          //原生获取上传进度的事件
          ///////////////////////上传中
          let arr = _this.$store.state.uploadProgressList;
          arr.findIndex((item, index, arr) => {
            if (item.name == file.name) {
              arr[index].progress = Math.floor(
                (progressEvent.loaded * 100) / progressEvent.total
              ) - 1;
              return 1;
            }
          });
          _this.$store.commit("updateUploadProgressList", arr);
          return progressEvent;
          /////////////////
        },
      };
      let upfile = {
      };
      upfile.file = file.file;
      upfile.fileType = getType(file.name)
      upfile.collection = 0
      let parentFolder = this.$store.state.parentFolder;
      upfile.parentFolder = parentFolder[parentFolder.length - 1].fileNumber
      let res = await this.$http.addFile(upfile, true, config);
      if (res !== undefined && res.status == 200) {
        //上传成功
        let arr = this.$store.state.uploadProgressList;
        arr.findIndex((item, index, arr) => {
          if (item.name == file.name) {
            arr[index].progress = 100;
            return true;
          }
        });

        //更新用户信息就会跟着更新视图列表
        this.$store.commit("updateUserInfo", true);


        this.$store.commit("updateUploadProgressList", arr);

        setTimeout(function () {
          let arr = _this.$store.state.uploadProgressList;
          let idx = arr.findIndex((item) => item.name == file.name);
          arr.splice(idx, 1);
          _this.$store.commit("updateUploadProgressList", arr);
        }, 3000);
        /////////////////////////////////////////
      } else {
        //上传失败
        let arr = this.$store.state.uploadProgressList;
        let idx = arr.findIndex((item) => item.name == file.name);
        arr.splice(idx, 1);
        this.$store.commit("updateUploadProgressList", arr);
        this.$message.error("文件上传失败，请稍后重试!");
        /////////////////////////////////////
      }
    },
    //搜索
    async fileSearch() {
      if(this.searchContent.length ==0)
      return 
      let res = await this.$http.getFileSearch(this.searchContent);
      if (res !== undefined && res.status == 200) {
        this.$store.commit("updateDataList", res.data);
      }
    },
    // 点击切换展示类型
    changeShowType() {
    },
  },

  created() { },
};
</script>

<style scoped>
.FunctionBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 280px);
  /* min-width: 980px; */
  /* background-color: pink; */
  height: 80px;
  padding: 10px 20px;
  position: relative;
  top: 0;
  left: 0px;
  z-index: 50;
}

.el-input /deep/ input {
  width: 14vw;
  border-radius: 30px;
  height: 35px;
  line-height: 35px;
  background-color: #f1f2f3;
  border: 1px solid #f1f2f3;
}

.el-input /deep/ i {
  line-height: 35px !important;
  color: #687176;
}

.right {
  display: flex;
  align-items: center;
}

.right i {
  font-size: 20px;
  color: #a0a0a0;
  font-weight: bold;
}

.right>div {
  margin-right: 0px;
}

.select {
  background-color: #696bcc !important;
  color: white !important;
  border: 1px solid #696bcc !important;
}

.selectAll:hover {
  background-color: #595bb3;
  color: white;
}

i {
  cursor: pointer;
}

.sortTypeItem {
  font-size: 13px;
  padding: 10px 0 10px 40px;
  position: relative;
  cursor: pointer;
  color: #595bb3;
}

.sortTypeItem i {
  position: absolute;
  left: 13px;
  color: #595bb3;
}

.sortTypeItem:hover {
  background-color: #efeff5;
}

.left {
  display: flex;
}

.uploadButton {
  margin-right: 10px;
}

.uploadProgress {
  width: 230px;
}

.multButtons {
  display: flex;
  align-items: center;
  font-size: 14px;
  transform: scale(0.9);
}

.tips {
  color: rgb(177, 177, 177);
}

.multButtonsContainer {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  color: rgb(97, 97, 97);
  margin-left: 10px;
  overflow: hidden;
}

.multButtonsContainer div {
  padding: 8px 15px;
  border-right: 1px solid #ccc;
  cursor: pointer;
}

.multButtonsContainer div:nth-last-child(1) {
  border: none;
}

.multButtonsContainer div:hover {
  background-color: #595bb3;
  color: white;
}


.tableHead {
  display: flex;
  line-height: 50px;
  height: 50px;
  width: calc(100vw - 260px);
  padding: 0 25px;
  box-sizing: border-box;
  font-size: 15px;
}

.tableName {
  line-height: 43px;
  width: 50%;
}

.tableHeadName {
  width: calc(50% + 43px);
  padding: 0;
}

.tableItemSize {
  width: 20%;
  padding-left: 80px;
  box-sizing: border-box;
}

.tableItemCreateTime {
  padding-left: 60px;
  box-sizing: border-box;
  width: 25%;
}

.tableCollect {
  width: 10%;
  text-align: center;
}
</style>

<template>
  <div class="login-vue" :style="bg">
    <div class="container">
      <p class="title">WELCOME</p>
      <div class="input-c">
        <!-- @on-focus 获得焦点时发生   @on-blur 失去焦点时发生  -->
        <el-input class="inoutclass" v-model="account" prefix-icon="el-icon-user" placeholder="用户名" size="mini"
          @focus="verifyAccountOrPwd"></el-input>
        <p class="error"></p>
      </div>

      <div class="input-c">
        <el-input class="inoutclass" style="width: 200px" v-model="pwd" prefix-icon="el-icon-lock" placeholder="密码"
          type="password" size="mini" @keyup.enter.native="submit" @focus="verifyAccountOrPwd"></el-input>
        <p class="error">{{ accountOrpwdError }}</p>
      </div>

      <div class="input-c" v-if="yzmShow">
        <el-input class="inoutclass" v-model="yzm" prefix-icon="el-icon-unlock" placeholder="验证码" @focus="verifyYzm"
          style="width: 118px" size="mini" @keyup.enter.native="submit"></el-input>
        <!-- <img class="yzmimg" @click="yzmimg" :src="yzmimg"> -->
        <!-- url为需要访问的接口地址-->
        <span @click="changeImgCode">
          <img :src="imgCode" style="float: right; width: 80px; height: 30px" />
        </span>
        <p class="error">{{ yzmError }}</p>
      </div>
      <el-button class="submit" type="primary" size="mini" @click="submit">登录</el-button>
      <p class="account">
        <span @click="visitor()">访客身份 </span> | <span @click="forgotPwd()"> 忘记密码</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      account: "",
      pwd: "",
      yzm: "",
      yzmShow: false,
      yzmError: "",
      imgCode: "",
      accountOrpwdError: "",
      bg: {},
    };
  },
  created() {
    
    //this.$axios.defaults.baseURL = 'http://' + window.location.hostname + ':8085/miko/'
    //this.$axios.defaults.wasURL = 'ws://' + window.location.hostname + ':8085/miko/'
    //this.bg.backgroundImage = 'url(' + require('../assets/imgs/bg0' + Math.floor(Math.random()*3)+ '.jpg') + ')'
    this.bg.backgroundImage = "url(" + require("../assets/imgs/bg01.jpg") + ")";
    this.imgCode = this.$axios.defaults.baseURL + "login/";
  },
  watch: {},
  methods: {
    changeImgCode() {
      var num = Math.ceil(Math.random() * 10); //生成一个随机数（防止缓存）
      this.imgCode = this.$axios.defaults.baseURL + "login/?" + num;
    },

    verifyAccountOrPwd(event) {
      event;
      this.accountOrpwdError = "";
    },
    verifyYzm(event) {
      event;
      this.yzmError = "";
    },
    async submit() {
      let res = await this.$http.login({
        loginName: this.account,
        password: this.pwd,
        yzm: this.yzm,
      });
      if (res !== undefined && res.status == 200) {
        // 登陆成功 设置用户信息
        this.$router.push({
          path: this.redirect || "/",
        });
      } else {
        this.changeImgCode()
        this.yzmShow = true;
      }
    },
    //提供一个访客身份用于共享通用文件和用于测试体验
    //async visitor() {
      // let res = await this.$http.login({
      //   loginName: "访客用户",
      //   password: "123456",
      //   yzm: this.yzm,
      // });
      // if (res !== undefined && res.status == 200) {
      //   // 登陆成功 设置用户信息
      //   this.$router.push({
      //     path: this.redirect || "/",
      //   });
      // } else {
      //   this.changeImgCode()
      //   this.yzmShow = true;
      // }
   // },
    visitor() {
       this.$message.success("当前版本不提供访客账户");
    },
    forgotPwd() {
      this.$message.success("忘记密码请联系管理员");
    }
  },

};
</script>

<style>
.inoutclass .el-input__inner {
  background: rgba(255, 255, 255, 0);
}

.login-vue {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.login-vue .container {
  background: rgba(255, 255, 255, 0.5);
  width: 300px;
  text-align: center;
  border-radius: 10px;
  padding: 30px;
}

.login-vue .ivu-input {
  background-color: transparent;
  color: #fff;
  outline: #fff;
  border-color: #fff;
}

.login-vue ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(255, 255, 255, 0.8);
}

.login-vue :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(255, 255, 255, 0.8);
}

.login-vue ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(255, 255, 255, 0.8);
}

.login-vue :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.8);
}

.login-vue .title {
  font-size: 16px;
  margin-bottom: 20px;
}

.login-vue .input-c {
  margin: auto;
  width: 200px;
}

.login-vue .input-y {
  margin: auto;
  width: 100px;
}

.login-vue .error {
  color: red;
  text-align: left;
  margin: 5px auto;
  font-size: 12px;
  padding-left: 30px;
  height: 20px;
}

.login-vue .submit {
  width: 200px;
}

.login-vue .account {
  margin-top: 30px;
}

.login-vue .account span {
  cursor: pointer;
}

.login-vue .ivu-icon {
  color: #eee;
}

.login-vue .ivu-icon-ios-close-circle {
  color: #777;
}
</style>

<template>
  <div class="UserInfoCard">
    <div class="storageProgressContainer">
      <div class="">
        {{ (userInfo.fileSize) | fileSizeString(userInfo.fileSize) }}
        /
        {{ (userInfo.fileSizeMax) | fileSizeString(userInfo.fileSizeMax) }}
      </div>
      <el-progress :percentage="
        storageProgress.toFixed(0) * 1 ? storageProgress.toFixed(0) * 1 : 0
      " :format="() => storageProgress + '%'" color="#696bcc" class="storageProgress"></el-progress>
    </div>

    <div class="userName">
      <div style="text-align: center">{{ userInfo.nickName }}</div>
    </div>
    <i class="iconfont icon-setting" @click.stop="open()"></i>
    <div class="menuContainer" v-show="isMenuShow">
      <div class="menu">
        <div class="group">
          <div class="menuItem" @click="changeNickName">修改昵称</div>
        </div>
        <div class="group">
          <div class="menuItem" @click="changePwd">修改密码</div>
          <div class="menuItem" @click="isAboutDialogShow = true">
            关于
          </div>
        </div>
        <div class="group">
          <div class="menuItem" @click="logout">退出登录</div>
        </div>
      </div>
    </div>

    <!-- 修改昵称的输入框dialog -->
    <el-dialog title="修改昵称" :visible.sync="isNickNameDialogShow" width="400px">
      <el-input v-model="newNickName" autocomplete="off" class="nickNameInput"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isNickNameDialogShow = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmNickName" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改密码的输入框dialog -->
    <el-dialog title="修改密码" :visible.sync="isPwdDialogShow" width="400px">
      <el-input v-model="pwd" autocomplete="off" class="pwdInput"></el-input>
      <el-input v-model="pwdRepeat" autocomplete="off"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isPwdDialogShow = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmPwd" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- '关于'框dialog -->
    <el-dialog title="关于miko" :visible.sync="isAboutDialogShow" width="300px" class="aboutDialog">
      <p>当前版本1.1.0</p>
      <p>更新时间2023年3月29日</p>
      <p>实现新功能:</p>
      <p>1.文件实时预览</p>
      <p>修复以下问题:</p>
      <p>1.文件列表显示页面优化</p>
      <p>2.后台服务性能优化</p>
      <p>3.文件安全检查自动化维护</p>
      <p>4.其他使用细节</p>
      <p>以下问题预计四月份大更新中修复</p>
      <p>1.各类文件图标显示不全待补充</p>
      <p>miko网盘项目组</p>
      <p>开发者bjtang(唐帮杰)</p>
      <p>电话微信同号:13075421065</p>
      <p>邮箱:bjtang@bjtang.com</p>
      <p>----------------------------------------</p>
      <p>代码改变世界！</p>
      
    </el-dialog>
  </div>
</template>

<script>
import { fileSizeString } from "../plugins/utils.js";
//import axios from "axios";
export default {
  name: "UserInfoCard",
  data() {
    return {
      userInfo: {},
      // 是否显示menu
      isMenuShow: false,
      // 是否显示修改昵称的dialog
      isNickNameDialogShow: false,
      // 新的昵称
      newNickName: "",
      // 是否显示about的dialog
      isAboutDialogShow: false,

      isPwdDialogShow: false,
      pwd: "",

      pwdRepeat: "",
    };
  },
  watch: {
    isMenuShow() {
      if (this.isMenuShow) {
        //TODO
        //不知道为什么点击会触发事件冒泡
        //使用@click.stop解决
        document.body.addEventListener('click', this.close)
      } else {
        document.body.removeEventListener('click', this.close)

      }
    },
    "$store.state.userInfoUp"(current) {
      if (current) {
        this.getUserInfo();
      }
    },
    '$route'(to, from) {
      // 对路由变化作出响应...
      from
      this.refresh()
    }
  },
  mounted() {
    //TODO 上线应该改为host,用nginx部署
    //window.console.log(window.location.host)
    //axios.defaults.baseURL = 'https://pan.bjtang.com/miko/'
    //axios.defaults.wasURL = 'wss://pan.bjtang.com/miko/'
    this.getUserInfo();
  },
  methods: {
    close() {
      this.isMenuShow = false
    },
    open() {
      this.isMenuShow = true
    },
    // 请求用户信息
    async getUserInfo() {
      let res = await this.$http.getMyUser();
      if (res !== undefined && res.status == 200) {
        this.userInfo = res.data;
        this.refresh()
      } else {
        this.$router.push("/login");
      }
      this.$store.commit("updateUserInfo", false);
      this.$store.commit("updateUserId", res.data.userId);
    },

    // 点击退出登录的回调
    logout() {
      this.$http.loginOut();
      this.$router.push("/login");
    },

    // 点击修改昵称的回调
    changeNickName() {
      this.newNickName = this.userInfo.nickName;
      this.isNickNameDialogShow = true;
      // 聚焦输入框
      // dialog在第一次打开前是不渲染的 所以里面的input需要在下一帧中获取
      this.$nextTick(() => {
        // console.log([document.querySelector(".nickNameInput")]);
        document.querySelector(".nickNameInput").children[0].focus();
      });
    },

    // 点击修改修改密码的回调
    changePwd() {
      this.isPwdDialogShow = true;
      // 聚焦输入框
      // dialog在第一次打开前是不渲染的 所以里面的input需要在下一帧中获取
      this.$nextTick(() => {
        // console.log([document.querySelector(".nickNameInput")]);
        document.querySelector(".pwdInput").children[0].focus();
      });
    },

    // 确认修改昵称
    async confirmNickName() {
      // 先判断昵称是否合法
      if (this.newNickName == "") {
        this.$message.warning("昵称不能为空哦!");
        return;
      } else if (this.newNickName == this.userInfo.nickname) {
        this.isNickNameDialogShow = false;
        return;
      }
      let data = this.userInfo;
      data.updateBy = null;
      data.founder = null;
      data.creationTime = null;
      data.updateTime = null;
      data.nickName = this.newNickName;
      let res = await this.$http.editMyUser(data);
      if (res !== undefined && res.status == 200) {
        this.$message.success("昵称修改成功!");
        this.isNickNameDialogShow = false;
        this.getUserInfo();
      }
    },
    // 确认修改密码
    async confirmPwd() {
      // 先判断密码合法
      if (this.pwd == "") this.$message.warning("密码不能为空哦!");
      else if (this.pwd != this.pwdRepeat)
        this.$message.warning("两次密码输入不一致哦!");

      let data = this.userInfo;
      data.updateBy = null;
      data.founder = null;
      data.creationTime = null;
      data.updateTime = null;
      data.password = this.pwd
      let res = await this.$http.editMyUser(data);
      if (res !== undefined && res.status == 200) {
        this.$message.success("密码修改成功!");
        this.isPwdDialogShow = false;
      }
    },
    refresh() {
      //遇到切换组建数据不显示问题
      //原因:触发数据更新操作在组建渲染之前完成,导致没有触发
      //解决:加上nextTick   2023.2.1 by @bjtang
      this.$nextTick(() => {
        this.$store.commit("updateParentFolder", { fileNumber: -1, fileName: '' });
      });
    },
  },
  computed: {
    // 内存进度条
    storageProgress() {
      return ((this.userInfo.fileSize / this.userInfo.fileSizeMax) * 100).toFixed(2) * 1;
    },
    storageSize() {
      return (this.userInfo.fileSize / this.userInfo.fileSizeMax).toFixed(2);
    },
  },
  filters: {
    fileSizeString
  },
};
</script>

<style scoped>
.UserInfoCard {
  height: 75px;
  width: 170px;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  color: #25262b;
  position: relative;
}

.storageProgressContainer {
  position: absolute;
  top: -45px;
  left: 25px;
  width: 140px;
  font-size: 13px;
}

.storageProgress {
  display: flex;
  align-items: center;
  width: 85%;
  align-items: center;
  margin-top: 5px;
}

.storageProgress /deep/ .el-progress__text {
  font-size: 13px !important;
  margin-left: 12px;
}

.avatar img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.userName {
  font-size: 14px;
  margin-left: 15px;
  width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.iconfont {
  font-size: 18px;
  color: rgb(104, 104, 104);
  cursor: pointer;
}

.menuContainer {
  position: absolute;
  right: 40px;
  top: 50px;
}

.menu {
  position: absolute;
  background-color: white;
  /* display: none; */
  bottom: 30px;
  width: 150px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  z-index: 3000;
  padding: 5px 5px;
  border-radius: 7px;
  font-size: 15px;
}

.UserInfoCard /deep/ .el-upload {
  width: 100%;
  cursor: unset;
  text-align: left;
}

.showMenu {
  display: block;
}

.group {
  padding: 4px 0;
  border-bottom: 1px solid #eee;
}

.group:last-child {
  border: none;
}

.group>div {
  padding: 4px 20px;
  font-size: 14px;
  color: rgb(70, 70, 70);
  user-select: none;
}

.group>div:hover {
  background-color: #696bcc;
  color: white;
}

.UserInfoCard /deep/ .el-dialog {
  border-radius: 10px;
}

.UserInfoCard /deep/ .aboutDialog {
  line-height: 20px;
}

.UserInfoCard /deep/ .aboutDialog .el-dialog__body {
  padding-top: 10px;
}
</style>

<template>
    <div>
        <img :src="img">
        <p>未到找指定页面</p>
		<!--返回按钮-->
		<el-button type="primary" @click="back">返回</el-button>
    </div>
</template>

<script>
export default {
    name: 'error',
    data() {
        return {
            img: require('../assets/imgs/404.jpg')
        }
    },
	methods:{
		back(){
			this.$router.go(-1);//返回上一层
		}
	},
}
</script>

<style scoped>
    div {
        width: 100%;
        height: 100%;
        background: #f8f5ec;
		text-align: center;
    }
    img {
        display: block;
        margin: auto;
    }
    p {
        font-size: 40px;
        text-align: center;
        margin-bottom: 100px;
    }
</style>
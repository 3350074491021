const APIS = {
    // 认证管理
    login: {
        method: 'post',
        url: '/login/'
    },
    loginOut: {
        method: 'delete',
        url: '/login/'
    },

    // 个人数据管理
    getMyUser: {
        method: 'get',
        url: '/index/my-user/'
    },
    editMyUser: {
        method: 'put',
        url: '/index/my-user/'
    },
    // 文件管理
    addFile: {
        method: 'post',
        url: '/file/'
    },
    addFolder: {
        method: 'post',
        url: '/file/folder/'
    },
    editFile: {
        method: 'put',
        url: '/file/'
    },
    moveFile: {
        method: 'put',
        url: '/file/move/'
    },
    //checkDataNodeFile 的用户权限版
    checkFile: {
        method: 'get',
        url: '/file/checkFile/'
    },
    delFile: {
        method: 'delete',
        url: '/file/'
    },
    delFolder: {
        method: 'delete',
        url: '/file/folder/'
    },
    getFileByParent: {
        method: 'get',
        url: '/file/'
    },
    getFileByCollection: {
        method: 'get',
        url: '/file/'
    },
    getFileSenior: {
        method: 'get',
        url: '/file/senior/'
    },
    getFileSearch: {
        method: 'get',
        url: '/file/search/'
    },
    
    getFileZoom: {
        method: 'get',
        url: '/file/zoom/'
    },
    download: {
        method: 'get',
        url: '/file/download/'
    },
    //获得预览key
    getFilePreview: {
        method: 'get',
        url: '/preview/url/'
    },
 //dataNodeDownload 的用户权限版
    nodeDownload: {
        method: 'get',
        url: '/file/nodeDownload/'
    },
    // 数据节点管理
    getDataNodeAll: {
        method: 'get',
        url: '/mikoFile/dataNode/'
    },
    getDataNodeFile: {
        method: 'get',
        url: '/mikoFile/download/'
    },
    checkDataNodeFile: {
        method: 'get',
        url: '/mikoFile/checkFile/'
    },
    delDataNodeFile: {
        method: 'delete',
        url: '/mikoFile/'
    },
    editDataNodeInitConf: {
        method: 'put',
        url: '/mikoFile/initConf/'
    },
    editDataNodeFileConf: {
        method: 'put',
        url: '/mikoFile/fileConf/'
    },
    rebootDataNode: {
        method: 'put',
        url: '/mikoFile/'
    },
    stopFileNode: {
        method: 'put',
        url: '/mikoFile/stop/'
    },
    stopFileNodeAll: {
        method: 'put',
        url: '/mikoFile/stopAll/'
    },
    startFileNode: {
        method: 'put',
        url: '/mikoFile/start/'
    },
    startFileNodeAll: {
        method: 'get',
        url: '/mikoFile/startAll/'
    },
    // 用户管理
    getUserAll: {
        method: 'get',
        url: '/user/'
    },
    addUser: {
        method: 'post',
        url: '/user/'
    },
    editUser: {
        method: 'put',
        url: '/user/'
    },
    checkUser: {
        method: 'get',
        url: '/user/check/'
    },
    changeUser: {
        method: 'get',
        url: '/user/change/'
    },
    delUser: {
        method: 'delete',
        url: '/user/'
    },


}
export default APIS
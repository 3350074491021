import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
import Login from "../components/Login.vue";
import Error from "../components/Error.vue";
import Files from "../components/Files.vue";
import Collectes from "../components/Collectes.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "",
    name: "index",
    component: Index,
    children: [
      //不要使用懒加载加载常用的组建!会导致意外的数据加载错误
      // {
      //   path: "/",
      //   component: () => import("../components/Files.vue")
      // },
      // {
      //   path: "/files",
      //   component: () => import("../components/Files.vue")
      // },
      {
        path: "/",
        component: Files
      },
      {
        path: "/files",
        component: Files
      },
      {
        path: "/collectes",
        component: Collectes
      },
      {
        path: "/users",
        component: () => import("../components/Users.vue")
      },
    ]
  },
  {
    path: "*",
    name: "error",
    component: Error
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

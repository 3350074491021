<template>
  <div class="RightClickMenu" v-show="childInfo.status">
    <!-- 文件点击右键的菜单框 -->
    <div class="menu" v-if="childInfo.menuType == 'file'" :style="{
      top: childInfo.y + 'px',
      left: childInfo.x + 'px',
    }">
      <div class="group">
        <div @click="openItem()">打开</div>
        <div @click="downloadFile()">下载</div>
      </div>
      <div class="group">
        <div v-if="item.collection == 0" @click="collectionFile(1)">
          收藏
        </div>
        <div v-if="item.collection == 1" @click="collectionFile(0)">取消收藏</div>
      </div>
      <div class="group">
        <div @click="renameFolder()">重命名</div>
        <div @click="deleteFile()">删除</div>
      </div>
      <div class="group">
        <div @click="showAttribute()">属性</div>
      </div>
    </div>

    <!-- 文件夹点击右键的菜单框 -->
    <div class="menu" v-else-if="childInfo.menuType == 'folder'" :style="{
      top: childInfo.y + 'px',
      left: childInfo.x + 'px',
    }">
      <div class="group">
        <div @click="openItem()">打开</div>
      </div>
      <div class="group">
        <div @click="renameFolder()">重命名</div>
        <div @click="deleteFolder()">删除</div>
      </div>
    </div>

    <!-- 空白处点击右键的菜单框 -->
    <div class="menu" v-else-if="childInfo.menuType == 'blank'" :style="{
      top: childInfo.y + 'px',
      left: childInfo.x + 'px',
    }">
      <!--为收藏夹做了额外优化 -->
      <div class="group" v-if="$route.path != '/collectes'">
        <div @click="createFolder()">新建文件夹</div>
      </div>
      <div class="group">
        <div @click="refresh()">刷新</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RightClickMenu",
  props: ['value', 'item'],
  data() {
    return {
      childInfo: {
        status: false,
        x: 0,
        y: 0,
        menuType: 'folder'
      },
    };
  },
  watch: {
    value: {
      handler(new_value, old_value) {
        old_value
        // window.console.log(new_value, old_value)
        if (new_value.status) {
          this.calculatePosition();
          document.body.addEventListener('click', this.close)
        } else {
          document.body.removeEventListener('click', this.close)
        }
      },
      deep: true,
    },
  },
  methods: {
    createFolder() {
      this.$store.commit("updateCreateFolder", true);
    },
    // 打开当前文件
    openItem() {
      //直接调用父组件方法
      this.$parent.openItem(this.item)
    },
    // 打开当前属性
    showAttribute() {
      //直接调用父组件方法
      this.$parent.showAttribute(this.item, this.value)
    },

    //让input框生效/获得焦点/全选文字
    renameFolder() {
      document.querySelector('#input' + this.item.fileNumber).select()
      document.querySelector('#input' + this.item.fileNumber).focus()
    },
    /**
    * 删除文件夹
    */
    async deleteFolder() {
      //直接调用父组件方法
      this.$parent.showDeleteFile(this.item, this.value)
      // let res = await this.$http.delFile({ id: this.item.fileNumber });
      // if (res !== undefined && res.status == 200) {
      //   this.refresh();
      //   //TODO
      // } else {
      //   //TODO
      // }
    },
    /**
    * 删除文件
    * //TODO 文件删除服务器应该多次返回删除进度
    */
    async deleteFile() {
      //直接调用父组件方法
      this.$parent.showDeleteFile(this.item, this.value)
    },

    /**
     * 收藏反选
     */
    async collectionFile(collection) {
      //重命名
      let fileData = this.item
      fileData.creationTime = null
      fileData.filePath = null
      fileData.collection = collection
      let res = await this.$http.editFile(fileData);
      if (res !== undefined && res.status == 200) {
        //TODO
      } else {
        //TODO
      }
    },
    /**
     * 下载文件
     */
    async downloadFile() {
      let alink = document.createElement('a');
      alink.style.display = 'none';
      alink.href = this.item.url;
      document.body.appendChild(alink);
      alink.click();
      document.body.removeChild(alink); //下载完成移除元素
    },
    // 点击下载文件的回调 新版本2023.1.18
    // 解决了图片和视频下载为浏览器自动打开问题

    // 点击下载文件的回调
    // downloadCurrentFile(type, item) {
    //   window.console.log(item)
    //   this.getBlob(item.url).then(blob => {
    //     this.saveAs(blob, item.fileName);
    //   });
    //   this.isMenuShow = false;
    // },

    // getBlob(url) {
    //   return new Promise(resolve => {
    //     const xhr = new XMLHttpRequest();
    //     xhr.open('GET', url, true);
    //     xhr.responseType = 'blob';
    //     xhr.onload = () => {
    //       if (xhr.status === 200) {
    //         resolve(xhr.response);
    //       }
    //     };
    //     xhr.send();
    //   });
    // },

    // saveAs(blob, filename) {
    //   var link = document.createElement('a');
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = filename;
    //   link.click();
    // },
    refresh() {
      this.$nextTick(() => {
        this.$store.commit("updateParentFolder", { fileNumber: -1, fileName: '' });
      });
    },
    /**
    * 计算菜单生成位置
    */
    calculatePosition() {
      let screen = this.getScreen();
      let x = 0;
      let y = 0;

      if (this.value.menuType == "file") {
        y = this.value.y + 215 > screen.height ? this.value.y - 215 : this.value.y;
      }
      if (this.value.menuType == "folder") {
        y = this.value.y + 105 > screen.height ? this.value.y - 105 : this.value.y;
      }
      if (this.value.menuType == "blank") {
        y = this.value.y + 75 > screen.height ? this.value.y - 75 : this.value.y;
      }
      x = this.value.x + 120 > screen.width ? this.value.x - 120 : this.value.x;
      this.childInfo = {
        x: x,
        y: y,
        status: this.value.status,
        menuType: this.value.menuType
      }


    },
    /**
     * 获取窗口大小
     */
    getScreen() {
      return {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      }
    },
    /**
   * 统一关闭菜单入口
   */
    close() {
      this.childInfo = {
        status: false,
        x: 0,
        y: 0,
        menuType: 'blank'
      }
      document.body.removeEventListener('click', this.close)
    },
  },
};
</script>

<style scoped>
.menu {
  width: 115px;
  padding: 3px 0px;
  box-sizing: border-box;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
  background-color: #fff;
  position: fixed;
  z-index: 1000;
}

.group {
  padding: 4px 0;
  border-bottom: 1px solid #eee;
}

.group:last-child {
  border: none;
}

.group>div {
  padding: 4px 20px;
  font-size: 14px;
  color: rgb(70, 70, 70);
  user-select: none;
}

.group>div:hover {
  background-color: #696bcc;
  color: white;
}
</style>

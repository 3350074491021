import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 用户数据更新
    userInfoUp: false,
    userId: 0,
    // 上层目录
    parentFolder: [{ fileNumber: 0, fileName: "根目录" }],
    // 当前上传进度数据数组
    uploadProgressList: [],
    // 数据列表
    dataList: [],
    createFolder: false
  },
  mutations: {

    // 更新当前所在目录
    updateParentFolder(state, arr) {
      let find = 0;
      for (let i = 0; i < state.parentFolder.length; i++) {
        //说明目录列表里面存在已有路径,删除操作
        if (state.parentFolder[i].fileNumber == arr.fileNumber) {
          state.parentFolder.splice(i + 1, state.parentFolder.length);
          find = 1;
          break;
        }
      }
      //说明是新增的路径
      if (find == 0) {
        state.parentFolder.push(arr)
      }
      //说明是刷新操作
      if (arr.fileNumber == -1) {
        state.parentFolder.pop()
      }
    },
    // 更新上传进度数据
    updateUploadProgressList(state, arr) {
      state.uploadProgressList = arr;
    },
    // 更新数据列表
    updateDataList(state, arr) {
      state.dataList = arr;
    },
    updateCreateFolder(state, arr) {
      state.createFolder = arr;
    },
    updateUserInfo(state, arr) {
      state.userInfoUp = arr;
    },
    updateUserId(state, arr) {
      state.userId = arr
    }
  },
  actions: {
  },
  modules: {
  }
})

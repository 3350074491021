<template>
  <!-- -->
  <div class="Attribute" v-show="status" @mousedown="moveDialog" :style="{
    top: y + 'px',
    left: x + 'px',
  }">
    <div class="header">
      {{
      (file.fileName) | handleLongString(10, 15)
      }}
      <i class="iconfont icon-close" @click="close()"></i>
    </div>
    <div class="group" v-for="(item, index) in socketMessage" :key="index">
      <div class="groupItem">
        <span class="left">{{ item }}</span>
      </div>
    </div>
    <div class="group">
      <div class="groupItem">
        <el-button @click="delFile()" type="danger" :disabled="delIs">删除</el-button>
        <el-button @click="close()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { handleLongString, fileSizeString } from "../plugins/utils.js";

export default {
  name: "Attribute",
  props: ["value"],
  data() {
    return {
      status: false,
      x: 0,
      y: 0,
      file: {
        fileName: "",
        fileType: "",
        fileSize: 0,
        creationTime: "",
      },
      logicPath: "",
      socket: {},
      socketMessage: [],
      delIs: false,
    };
  },
  watch: {
    value: {
      handler(new_value, old_value) {
        old_value;
        if (new_value.status) {
          this.calculatePosition();
          this.getData();
          this.file = new_value;
          window.console.log(new_value)
          if (new_value.fileType == "folder") {
            this.socketMessage.push("删除该文件夹下所有文件(不会递归删除)");
            this.socketMessage.push("您应该充分认识到删除文件夹的风险");
          }
        } else {
          //TODO
          this.close();
        }
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    WSinit() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        this.socket = new WebSocket(
          axios.defaults.wasURL + "websocket/" + this.$store.state.userId
        );
        // 监听socket连接
        this.socket.onopen = this.openWS;
        // 监听socket错误信息
        this.socket.onerror = this.errorWS;
        // 监听socket消息
        this.socket.onmessage = this.getMessageWS;
        this.socket.onclose = this.closeWS;
      }
    },
    openWS() {
      window.console.log("socket连接成功");
    },
    errorWS(err) {
      window.console.log("连接错误" + err);
    },
    getMessageWS(msg) {
      //window.console.log(msg);
      //this.msg = msg.data;
      if (msg.data == "完成") {
        this.socket.close();
      }

      let data = JSON.parse(msg.data);
      if (data.status == 200) {
        this.socketMessage.push(data.data);
      }
    },
    closeWS(event) {
      window.console.log("socket已经关闭" + event.reason);
    },
    sendMessageWS() {
      this.socket.send("测试消息");
    },

    getData() {
      this.WSinit();
    },
    async delFile() {
      if (this.value.fileType == "folder") {
        //删除文件夹
        let res = await this.$http.delFolder({ id: this.value.fileNumber });
        if (res !== undefined && res.status == 200) {
          //TODO
          this.$store.commit("updateUserInfo", true);
          this.delIs = true;
        } else {
          //TODO
        }
      } else {
        //删除文件
        let res = await this.$http.delFile({ id: this.value.fileNumber });
        if (res !== undefined && res.status == 200) {
          //TODO
          this.$store.commit("updateUserInfo", true);
          this.delIs = true;
        } else {
          //TODO
        }
      }
    },
    // 拖动属性框的回调
    moveDialog(e) {
      let dialog;
      if (e.target.className != "Attribute") {
        dialog = e.target.parentNode;
      } else {
        return;
      }
      //算出鼠标相对元素的位置
      let disX = e.clientX - dialog.offsetLeft;
      let disY = e.clientY - dialog.offsetTop;
      let pageWidth = document.body.clientWidth;
      let pageHeight = document.body.clientHeight;
      const _this = this;
      // console.log(pageWidth, pageHeight);
      document.onmousemove = (e) => {
        // console.log(e);
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        top = top < 100 ? 100 : top;
        top = top > pageHeight - 225 ? pageHeight - 225 : top;
        left = left < 200 ? 200 : left;
        left = left > pageWidth - 325 ? pageWidth - 325 : left;
        // console.log(top, left);

        //移动当前元素
        dialog.style.left = left + "px";
        dialog.style.top = top + "px";

        _this.childInfo = {
          x: left,
          y: top,
          status: this.value.status,
        };
      };
      // 鼠标松开时清空事件
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    /**
     * 计算菜单生成位置
     */
    calculatePosition() {
      let screen = this.getScreen();
      let x = 0;
      let y = 0;
      y =
        this.value.y + 100 > screen.height ? this.value.y - 100 : this.value.y;
      x = this.value.x + 245 > screen.width ? this.value.x - 245 : this.value.x;

      this.x = x;
      this.y = y;
      this.status = this.value.status;
    },
    /**
     * 获取窗口大小
     */
    getScreen() {
      return {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      };
    },
    /**
     * 统一关闭菜单入口
     */
    close() {
      this.status = false;
      this.x = 0;
      this.y = 0;
      if (this.socket.url) {
        this.socket.close();
      }

      this.socketMessage = [];
      this.delIs = false;
    },
  },
  created() {},
  filters: {
    handleLongString,
    fileSizeString,
  },
};
</script>

<style scoped>
.Attribute {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
  padding-bottom: 10px;
  border-radius: 10px;
  width: 300px;
  font-size: 14px;
  position: fixed;
  background-color: #fff;
  z-index: 100;
}

.header {
  padding: 15px;
  position: relative;
  cursor: move;
  user-select: none;
}

.header i {
  position: absolute;
  right: 10px;
  color: rgb(124, 124, 124);
  cursor: pointer;
}

.group {
  padding: 5px 0;
  margin: 0 15px;
  border-top: 1px solid #ddd;
}

.groupItem {
  padding: 5px 0;
  display: flex;
}

.groupItem .left {
  width: 150px;
}

.groupItem > div {
  padding: 4px 20px;
  font-size: 14px;
  color: rgb(70, 70, 70);
  user-select: none;
}

.groupItem > div:hover {
  background-color: #696bcc;
  color: white;
}
</style>

import axios from 'axios'
import service from './Apis'
// import { Toast } from 'vant';
import {Message} from 'element-ui';
import router from "../router"
// service 循环遍历输出不同的请求方法
// let instance = axios.create({
//     baseURL: ''
// })
// 包裹请求方法的容器

// 设置基础URL
//axios.defaults.baseURL = 'http://59.45.37.58:8085/miko/'
//axios.defaults.wasURL = 'ws://59.45.37.58:8085/miko/'
axios.defaults.baseURL = 'https://pan.bjtang.com/miko/'
axios.defaults.wasURL = 'wss://pan.bjtang.com/miko/'
//axios.defaults.baseURL = 'http://www.bjtang.com:8001/book/'
// 设置请求超时时间
axios.defaults.timeout = 50000
// 设置跨域相关
axios.defaults.withCredentials = true;

const Http = {}

// 请求格式/参数的统一
for (let key in service) {
    let api = service[key];
    // method
    // 避免进入回调地狱
    Http[key] = async function (
        params, //请求参数
        isFormData = false,  //是否是form-data请求
        config = {},   //配置参数

    ) {
        // let url = api.url
        let newParams = {}
        if (params && isFormData) {
            newParams = new FormData()
            for (let i in params) {
                newParams.append(i, params[i])
            }
        } else {
            newParams = params
        }
        let response = {}   // 请求的返回值
        // 不同请求的判断
        if (api.method === 'put' || api.method === 'post' || api.method === 'patch') {
            try {
                let url = null;
                if (config != undefined || config != null)
                    if (config.data != undefined || config.data != null)
                        url = api.url + config.data
                    else
                        url = api.url

                else
                    url = api.url
                //为了方便带路径数据的post,put请求,简化API数据结构
                response = await axios[api.method](url, newParams, config)
            } catch (err) {
                response = err
            }
            //delete请求
        } else if (api.method === 'delete') {
            //config.params = newParams
            // delete只有两个参数,一个url,一个config 没有params   ,params存放在 config.data里,所以应该用以下赋值方式
            config.data = newParams
            try {
                response = await axios[api.method](api.url, config)
            } catch (err) {
                response = err
            }
        } else {
            // get 需要把参数拼接到url后面
            let url = null;
            if (newParams != undefined || newParams != null)
                url = api.url + newParams
            else
                url = api.url
            // config.data = newParams
            try {
                response = await axios[api.method](url, config)
            } catch (err) {
                response = err
            }
        }
        return response
    }
}

// 拦截器的添加
// 请求拦截器
axios.interceptors.request.use(config => {
    // 发起请求前做些什么
    // Message('正在请求');
    return config
}, err => {
    // 请求错误
    Message.error("请求错误");
    window.console.log(err)
})

// 响应拦截器
axios.interceptors.response.use(res => {
    if (res.data.status == 200) {
        //   window.console.log(res.data.meta.status);
        //   window.console.log(res.data.meta.msg);
        window.console.log(res.data.data);
        return res.data
    }
    else {
        if (res.data.data != null)
           // Toast(res.data.msg + " " + res.data.status + " " + res.data.data);
           Message.warning(res.data.msg + " " + res.data.status + " " + res.data.data);
        else
        Message.warning(res.data.msg + " " + res.data.status);
           // Toast(res.data.msg + " " + res.data.status);
        window.console.log(res.data.status);
        window.console.log(res.data.msg);
        window.console.log(res.data.data);
        if (res.data.status == 401)
            router.push("/login");

        return res.data
        // Message('请求错误');
    }
}, err => {
    Message.error("请求错误");
    window.console.log(err)
})

export default Http
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
import axios from 'axios'
//axios.defaults.withCredentials = true;
// 打印
import Print from 'vue-print-nb'
// 绘图
// import echarts from "echarts";
// excel
// import FileSaver from 'file-saver';
// import XLSX from 'xlsx';
import http from './service/http'
import { Lazyload } from 'vant';

Vue.use(Lazyload);
// 注册
Vue.use(Print)
// Vue.use(XLSX)
// Vue.use(FileSaver)
// Vue.prototype.$echarts = echarts;
Vue.prototype.$http = http
Vue.prototype.$axios = axios
Vue.config.productionTip = false
// 导入全局css
import './assets/css/base.css'
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.childInfo.status),expression:"childInfo.status"}],staticClass:"RightClickMenu"},[(_vm.childInfo.menuType == 'file')?_c('div',{staticClass:"menu",style:({
    top: _vm.childInfo.y + 'px',
    left: _vm.childInfo.x + 'px',
  })},[_c('div',{staticClass:"group"},[_c('div',{on:{"click":function($event){return _vm.openItem()}}},[_vm._v("打开")]),_c('div',{on:{"click":function($event){return _vm.downloadFile()}}},[_vm._v("下载")])]),_c('div',{staticClass:"group"},[(_vm.item.collection == 0)?_c('div',{on:{"click":function($event){return _vm.collectionFile(1)}}},[_vm._v(" 收藏 ")]):_vm._e(),(_vm.item.collection == 1)?_c('div',{on:{"click":function($event){return _vm.collectionFile(0)}}},[_vm._v("取消收藏")]):_vm._e()]),_c('div',{staticClass:"group"},[_c('div',{on:{"click":function($event){return _vm.renameFolder()}}},[_vm._v("重命名")]),_c('div',{on:{"click":function($event){return _vm.deleteFile()}}},[_vm._v("删除")])]),_c('div',{staticClass:"group"},[_c('div',{on:{"click":function($event){return _vm.showAttribute()}}},[_vm._v("属性")])])]):(_vm.childInfo.menuType == 'folder')?_c('div',{staticClass:"menu",style:({
    top: _vm.childInfo.y + 'px',
    left: _vm.childInfo.x + 'px',
  })},[_c('div',{staticClass:"group"},[_c('div',{on:{"click":function($event){return _vm.openItem()}}},[_vm._v("打开")])]),_c('div',{staticClass:"group"},[_c('div',{on:{"click":function($event){return _vm.renameFolder()}}},[_vm._v("重命名")]),_c('div',{on:{"click":function($event){return _vm.deleteFolder()}}},[_vm._v("删除")])])]):(_vm.childInfo.menuType == 'blank')?_c('div',{staticClass:"menu",style:({
    top: _vm.childInfo.y + 'px',
    left: _vm.childInfo.x + 'px',
  })},[(_vm.$route.path != '/collectes')?_c('div',{staticClass:"group"},[_c('div',{on:{"click":function($event){return _vm.createFolder()}}},[_vm._v("新建文件夹")])]):_vm._e(),_c('div',{staticClass:"group"},[_c('div',{on:{"click":function($event){return _vm.refresh()}}},[_vm._v("刷新")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
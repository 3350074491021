<template>
  <div class="Collectes">
    <!-- <function-bar></function-bar> -->
    <icon-type-list></icon-type-list>
  </div>
</template>

<script>
// import FunctionBar from "./FunctionBar.vue";
import IconTypeList from "./IconTypeList.vue";

export default {
  name: "Collectes",
  components: {
    // FunctionBar,
    IconTypeList,
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  watch: {
    "$store.state.parentFolder"(current) {
      current
      this.getData()
    },
  },
  methods: {
    // 请求收藏文件列表
    async getData() {
      let res = await this.$http.getFileByCollection();
      if (res !== undefined && res.status == 200) {
        this.$store.commit("updateDataList", res.data);
      }
    },
  },
};
</script>

<style scoped>

</style>
